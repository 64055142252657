// extracted by mini-css-extract-plugin
export const section = "resume-module--section--bROJ-";
export const experience = "resume-module--experience--wW51S";
export const header = "resume-module--header--nCkaJ";
export const company = "resume-module--company--X3nfV";
export const dates = "resume-module--dates--2Q1-a";
export const education = "resume-module--education--bDP4L";
export const skillLists = "resume-module--skillLists--2Pzp7";
export const skillList = "resume-module--skillList--2OwPp";
export const skills = "resume-module--skills--1IVAa";
export const skill = "resume-module--skill--7CADF";